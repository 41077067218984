import { IonSpinner } from '@ionic/react';
import { LoaderContainer } from './Loader.styles';

const Loader = () => {
  return (
    <LoaderContainer>
      <IonSpinner name="bubbles" />
    </LoaderContainer>
  );
};

export default Loader;
