import styled from 'styled-components';

const DesktopNavBar: any = styled.nav`
  display: flex;
  flex-wrap: wrap;
  margin: 0 12px;

  justify-content: ${(props: any) => (props.isMobile ? 'space-between' : 'center')};
  align-items: center;

  .site-logo-container {
    display: flex;
  }
  .site-logo-container img {
    height: 75px;
    object-fit: cover;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    flex-direction: row;
    align-items: center;
    margin: 0 0 0 12px;
    padding: 0;

    li {
      a:not(.profile-link, .cart-link) {
        padding: 30px 20px;
        text-decoration: none;
        transition: 0.5s;
        text-transform: uppercase;
        border-bottom: 4px solid transparent;
      }

      a:hover:not(.profile-link, .cart-link) {
        background-color: #ededed;
      }
      .active-route:not(.profile-link, .cart-link) {
        background-color: #ededed;
        font-weight: bold;
        padding: 30px 20px;
        border-bottom: 4px solid var(--ion-color-primary);
      }

      .profile-logo {
        object-fit: cover;
        width: 60px;
        height: 60px;
        border-radius: 100%;
      }
    }

    .icon-container {
      position: relative;

      ion-icon {
        font-size: 42px;
        // border: 1px solid;
        // border-radius: 100%;
        padding: 8px;
        margin-right: 8px;
      }

      ion-badge {
        position: absolute;
        left: 40px;
        top: -50px;
      }
    }

    .icon-container.mobile {
      ion-icon {
        font-size: 32px;
      }

      ion-badge {
        position: absolute;
        left: 40px;
        top: -38px;
      }
    }

    .sub-menu-container:hover .sub-menu-trigger {
      background-color: #ededed;
    }

    .sub-menu-container {
      position: relative;
      display: inline-block;

      :hover .sub-menu {
        display: block;
      }

      .sub-menu {
        display: none;
        position: absolute;
        background-color: #ededed;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
        z-index: 1;
        top: 46px;
        left: -12px;

        li {
          height: 50px;
          display: flex;
          align-items: center;
        }
        li:hover {
          background-color: #bbbbbb;
        }
        a {
          padding: 0px;
          padding-left: 22px;
        }
        a:hover {
          background-color: unset;
        }
      }
    }
  }

  @media (max-width: 1330px) {
    ul > li > .active-route {
      padding: 20px 20px;
    }
    ul > li > a:hover:not(.profile-link, .cart-link) {
      padding: 20px 20px;
    }

    .sub-menu-container:hover .sub-menu {
      display: block;
      top: 38px;
    }
    .sub-menu-container:hover .sub-menu-trigger {
      padding-top: 20px;
    }
  }

  @media screen and (max-width: 450px) {
    .site-logo-container img {
      margin-top: 8px;
      height: 50px;
    }
  }
`;

export { DesktopNavBar };
