import styled from 'styled-components';

const ContactFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  .icons {
    max-width: 600px;
    align-self: center;
    padding: 0 18px;
  }
  .form-error {
    color: var(--ion-color-danger);
  }
`;

export { ContactFormContainer };
