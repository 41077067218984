import { PointCountContainer } from './PointsCount.styles';

const PointsCount = ({ name, points }: { name: string; points: number }) => {
  return (
    <PointCountContainer>
      <p>
        Puntos <span>{name}</span> <span>{points}</span>
      </p>
    </PointCountContainer>
  );
};

export default PointsCount;
