import { ErrorFallBackContainer } from './ErrorFallback.styles';

const ErrorFallback = () => {
  return (
    <ErrorFallBackContainer>
      <h1>Ha ocurrido un error inesperado, por favor intenta refrescar la página</h1>
      <p>Si el problema persiste contacta a soporte</p>
    </ErrorFallBackContainer>
  );
};

export default ErrorFallback;
