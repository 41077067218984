import { createSlice } from '@reduxjs/toolkit';

const companyConfigSlice = createSlice({
  name: 'companyConfig',
  initialState: {
    empresa: null,
    configuracion: null,
    skin: { primary: null, secondary: null, tertiary: null, success: null, warning: null, danger: null },
    categorias: [],
    conveniosCategories: [],
    descriptores: [],
    centro_costo: [],
    logros: [],
  },
  reducers: {
    setCompanyConfig: (state, action) => {
      const { empresa, configuracion, skin, categorias, convenios, descriptores } = action.payload;
      state.empresa = empresa;
      state.configuracion = configuracion;
      state.skin.primary = skin.primary;
      state.skin.secondary = skin.secondary;
      state.skin.tertiary = skin.tertiary;
      state.skin.success = skin.success;
      state.skin.warning = skin.warning;
      state.skin.danger = skin.danger;
      state.categorias = categorias;
      state.conveniosCategories = convenios;
      state.descriptores = descriptores;
      state.centro_costo = action.payload.centro_costo;
      state.logros = action.payload.logros;
    },
  },
});

export const { setCompanyConfig } = companyConfigSlice.actions;
export const selectCurrentSkin = (state: any) => state.companyConfig.skin;
export const selectCurrentCompany = (state: any) => state.companyConfig.empresa;
export const selectCurrentCompanyCostCenters = (state: any) => state.companyConfig.centro_costo;
export const selectCurrentCompanyAchievements = (state: any) => state.companyConfig.logros;
export const selectCurrentCompanyConfig = (state: any) => state.companyConfig.configuracion;
export const selectCurrentBenefitsCategories = (state: any) => state.companyConfig.categorias;
export const selectCurrentConveniosCategories = (state: any) => state.companyConfig.conveniosCategories;
export const selectSectionDescription = (idseccion: any) => (state: any) => {
  if (Array.isArray(state.companyConfig.descriptores)) {
    return state.companyConfig.descriptores.find((item: any) => item.idseccion === idseccion);
  }
  return null;
};

export default companyConfigSlice.reducer;
