import { apiSlice } from './apiSlice';

export const perfil = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    profileInfo: builder.query({
      query: ({ reporte, FechaIni, FechaFin }) => ({
        url: `/perfil/info`,
        method: 'GET',
      }),
    }),
    updateProfileInfo: builder.mutation({
      query: ({ nombre, email, fecha_nacimiento, direccion, celular }) => ({
        url: `/perfil/info`,
        body: {
          nombre,
          email,
          fecha_nacimiento,
          direccion,
          celular,
        },
        method: 'POST',
      }),
    }),

    getProfilePoints: builder.query({
      query: () => ({
        url: `/perfil/puntos`,
        method: 'GET',
      }),
    }),

    getBeneficiary: builder.query({
      query: ({ formData }) => ({
        url: `/perfil/beneficiarios`,
        method: 'GET',
      }),
    }),

    createBeneficiary: builder.mutation({
      query: ({ formData }) => ({
        url: `/perfil/beneficiarios`,
        body: formData,
        method: 'POST',
      }),
    }),

    getProfileVolunteering: builder.query({
      query: ({ formData }) => ({
        url: `/perfil/voluntariados`,
        method: 'GET',
      }),
    }),

    getProfileActivites: builder.query({
      query: ({ formData }) => ({
        url: `/perfil/actividades`,
        method: 'GET',
      }),
    }),

    getProfileBenefits: builder.query({
      query: ({ formData }) => ({
        url: `/perfil/beneficios`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyProfileInfoQuery,
  useUpdateProfileInfoMutation,
  useLazyGetBeneficiaryQuery,
  useCreateBeneficiaryMutation,
  useLazyGetProfileVolunteeringQuery,
  useLazyGetProfileActivitesQuery,
  useLazyGetProfileBenefitsQuery,
  useLazyGetProfilePointsQuery,
} = perfil;
