import { lazy } from 'react';

const PUBLIC_ROUTES = [
  {
    path: '/login',
    exact: true,
    component: lazy(() => import('../pages/Login/Login')),
  },
  {
    path: '/redimir',
    exact: true,
    component: lazy(() => import('../pages/Redimir/Redimir')),
  },
  {
    path: '/change-password',
    exact: true,
    component: lazy(() => import('../pages/ChangePassword/ChangePassword')),
  },
];

const PRIVATE_ROUTES = [
  {
    path: '',
    exact: true,
    component: lazy(() => import('../pages/Home/Home')),
  },
  {
    path: 'beneficios',
    component: lazy(() => import('../pages/Beneficios/Beneficios')),
  },
  {
    path: 'actividades',
    component: lazy(() => import('../pages/Actividades/Actividades')),
  },
  {
    path: 'voluntariados',
    component: lazy(() => import('../pages/Voluntariados/Voluntariados')),
  },
  {
    path: 'convenios',
    component: lazy(() => import('../pages/Convenios/Convenios')),
  },
  {
    path: 'mi-equipo',
    component: lazy(() => import('../pages/Equipo/Equipo')),
  },
  {
    path: 'perfil',
    component: lazy(() => import('../pages/Perfil/Perfil')),
  },
  {
    path: 'carrito',
    component: lazy(() => import('../pages/Carrito/Carrito')),
  },
  {
    path: 'recognition-feed',
    exact: true,
    component: lazy(() => import('../pages/RecognitionFeed/RecognitionFeed')),
  },
  {
    path: 'recognition-feed/:idrecognition',
    component: lazy(() => import('../pages/RecognitionDetail/RecognitionDetail')),
  },
];

export { PUBLIC_ROUTES, PRIVATE_ROUTES };
