import { apiSlice } from './apiSlice';

export const message = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    message: builder.mutation({
      query: ({ comentario }) => ({
        url: `/message/`,
        body: {
          comentario,
          lang: 'es',
        },
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useMessageMutation } = message;
