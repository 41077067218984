import { IonButton, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonText, IonTextarea, IonTitle, IonToolbar } from '@ionic/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMessageMutation } from '../../redux/features/api/message';

import { IonItemForm } from '../UI/IonItemForm/IonItemForm.styles';
import Modal from '../UI/Modal/Modal';
import { ContactFormContainer } from './ContactModal.styles';

const ContactModal = ({ isMobile, icon, title }: { isMobile?: boolean; icon?: string; title?: string }) => {
  const [sendMessage, { isLoading: isLoadingSending }] = useMessageMutation();
  const [recoverFailedMsg, setRecoverFailedMsg] = useState('');
  const [recoverSent, setRecoverSent] = useState(false);
  const [recoverModalOpen, setRecoverModalOpen] = useState(false);
  const recoverForm = useForm({ mode: 'onBlur' });

  const onSubmitRecover = async (data: any) => {
    try {
      const { comentario } = data;
      await sendMessage({ comentario }).unwrap();
      setRecoverSent(true);
    } catch (error) {
      console.log({ error });
      setRecoverFailedMsg('Ocurrió un error al enviar la información, por favor intenta nuevamente o contacta con soporte');
    }
  };

  const dismissRecoverModal = () => {
    setRecoverModalOpen(false);
    setRecoverSent(false);
    setRecoverFailedMsg('');
    recoverForm.reset({ comentario: '' });
  };

  return (
    <>
      {isMobile ? (
        <IonItem lines="none" detail={false} onClick={() => setRecoverModalOpen(true)}>
          <IonIcon slot="start" icon={icon} md={icon} />
          <IonLabel>{title}</IonLabel>
        </IonItem>
      ) : (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a onClick={() => setRecoverModalOpen(true)}>Contáctanos</a>
      )}

      {/*       <IonButton fill="clear" onClick={() => setRecoverModalOpen(true)}>
        Contáctanos
      </IonButton> */}
      <Modal isOpen={recoverModalOpen} onWillDismiss={dismissRecoverModal} smallModal>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Contáctanos</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          {!recoverSent ? (
            <>
              <p>Contáctanos, escribe tu duda y envía el mensaje</p>
              <ContactFormContainer onSubmit={recoverForm.handleSubmit(onSubmitRecover)}>
                <IonItemForm>
                  <IonLabel position="floating">Tu mensaje</IonLabel>
                  <IonTextarea {...recoverForm.register('comentario', { required: true })}></IonTextarea>
                </IonItemForm>
                {recoverFailedMsg && (
                  <IonText color="danger" className="ion-text-center">
                    <span>{recoverFailedMsg}</span>
                  </IonText>
                )}
                {recoverForm.formState.errors.comentario && (
                  <IonText color="danger" className="ion-text-center">
                    <span>Debes escribir un mensaje para poder enviarlo</span>
                  </IonText>
                )}
                <IonButton type="submit" disabled={isLoadingSending}>
                  {isLoadingSending ? 'Enviando...' : 'Enviar'}
                </IonButton>
              </ContactFormContainer>
            </>
          ) : (
            <>
              <p>Información enviada y una vez sea revisada se te responderá en breve</p>
              <IonButton type="submit" onClick={() => dismissRecoverModal()}>
                Cerrar
              </IonButton>
            </>
          )}
        </IonContent>
      </Modal>
    </>
  );
};

export default ContactModal;
