import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
//@ts-ignore
import PWAPrompt from 'react-ios-pwa-prompt';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './redux/store';

/* const store = configureStore({}) */

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
    <PWAPrompt
      promptOnVisit={1}
      timesToShow={3}
      copyClosePrompt="Cerrar"
      permanentlyHideOnDismiss={false}
      copyTitle="Instalar la aplicación"
      copyBody="Puedes instalar esta aplicación en tu dispositivo para tener acceso a ella desde tu pantalla principal"
      copyShareButtonLabel="Presiona el botón 'Compartir'"
      copyAddHomeButtonLabel="Presiona el botón 'Agregar a Inicio'"
    />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
