import { Redirect, Route } from 'react-router-dom';
import { useIsAuthenticated } from '../redux/features/auth/useIsAuthenticated';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
