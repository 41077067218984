import { IonBadge, IonIcon, IonMenuButton } from '@ionic/react';
import { cartOutline, ribbonOutline } from 'ionicons/icons';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import useMediaQuery from '../../../hooks/useMediaQuery';

import { selectCurrentUser } from '../../../redux/features/auth/authSlice';
import { selectCurrentBenefitsCategories, selectCurrentCompanyConfig } from '../../../redux/features/companyConfigSlice/companyConfigSlice';
import { ACTIVE_COMPANY_ID, AWS_BUCKET } from '../../../utils/constants';
import ContactModal from '../../ContactModal/ContactModal';

import portraitPlaceholder from '../../../assets/img/portrait_placeholder.jpg';

import { DesktopNavBar } from './DesktopMenu.styles';
import { clearCart, selectCart, setCart } from '../../../redux/features/cart/cartSlice';

import { useEffect, useState } from 'react';
import { useLazyRequestCartItemsQuery } from '../../../redux/features/api/cart';

const DesktopMenu = () => {
  const user = useSelector(selectCurrentUser);
  const config = useSelector(selectCurrentCompanyConfig);
  const cartItems: any[] = useSelector(selectCart);
  const beneficiosActivos = useSelector(selectCurrentBenefitsCategories);
  const activateMobileMenu = useMediaQuery('(max-width: 1134px)');
  const dispatch = useDispatch();
  const [cartItemsApi] = useLazyRequestCartItemsQuery();
  const [profilePhotoSuccess, setProfilePhotoSuccess] = useState(true);

  const getCartItems = async () => {
    try {
      const cartItems: any = await cartItemsApi(null).unwrap();
      if (cartItems.elementos && cartItems.elementos.length > 0) {
        //console.log({ cartItems });
        dispatch(setCart(cartItems.elementos));
      } else {
        dispatch(clearCart());
        /*         console.log('No hay items en el carrito'); */
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getCartItems();
  }, []);

  return (
    <DesktopNavBar isMobile={activateMobileMenu}>
      <div className="site-logo-container">
        <Link to="/main">
          <img src={`${AWS_BUCKET}logo-${ACTIVE_COMPANY_ID}.png`} alt="4B ForBeyond Logo" />
        </Link>
      </div>
      {activateMobileMenu ? (
        <ul>
          <li>
            <NavLink to="/main/recognition-feed" className="cart-link">
              <span className="icon-container mobile">
                <IonIcon icon={ribbonOutline} />
                <IonBadge color="primary">1+</IonBadge>
              </span>
            </NavLink>
            <NavLink to="/main/carrito" className="cart-link">
              <span className="icon-container mobile">
                <IonIcon icon={cartOutline} />
                <IonBadge color="primary">{cartItems.length}</IonBadge>
              </span>
            </NavLink>
          </li>
          <li>
            <IonMenuButton />
          </li>
        </ul>
      ) : (
        <ul>
          {/*           <li>
            <NavLink to="/main">Home</NavLink>
          </li> */}
          <li className="sub-menu-container">
            <NavLink to="/main/beneficios" activeClassName="active-route" className="sub-menu-trigger">
              Beneficios
            </NavLink>
            <ul className="sub-menu">
              {beneficiosActivos.map((beneficio: any) => (
                <li key={beneficio.idbeneficio_categoria}>
                  <NavLink aria-label={`Ir a los beneficios ${beneficio.categoria}`} to={`/main/beneficios?idcategoria=${beneficio.idbeneficio_categoria}`}>
                    {beneficio.categoria}
                  </NavLink>
                </li>
              ))}
            </ul>
          </li>
          {!!config?.ACTactivo && (
            <li>
              <NavLink to="/main/actividades" activeClassName="active-route">
                Actividades
              </NavLink>
            </li>
          )}
          {!!config?.VOLactivo && (
            <li>
              <NavLink to="/main/voluntariados" activeClassName="active-route">
                Voluntariados
              </NavLink>
            </li>
          )}

          {!!config?.CNVactivo && (
            <li>
              <NavLink to="/main/convenios" activeClassName="active-route">
                Convenios
              </NavLink>
            </li>
          )}
          {!!user?.equipo && (
            <li>
              <NavLink to="/main/mi-equipo/helpers" activeClassName="active-route">
                Mi equipo
              </NavLink>
            </li>
          )}
          <li>
            <ContactModal />
          </li>
          <li>
            <NavLink to="/main/recognition-feed" className="cart-link">
              <span className="icon-container">
                <IonIcon icon={ribbonOutline} />
                <IonBadge color="primary">1+</IonBadge>
              </span>
            </NavLink>
            <NavLink to="/main/carrito" className="cart-link">
              <span className="icon-container">
                <IonIcon icon={cartOutline} />
                <IonBadge color="primary">{cartItems.length}</IonBadge>
              </span>
            </NavLink>

            <NavLink to="/main/perfil/info" className="profile-link">
              {profilePhotoSuccess ? (
                <LazyLoadImage src={`${AWS_BUCKET}${user.foto}`} alt="Profile" className="profile-logo" onError={() => setProfilePhotoSuccess(false)} />
              ) : (
                <LazyLoadImage src={portraitPlaceholder} alt="Profile" className="profile-logo" />
              )}
            </NavLink>
          </li>
        </ul>
      )}
    </DesktopNavBar>
  );
};

export default DesktopMenu;
