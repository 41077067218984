import { apiSlice } from '../api/apiSlice';

export const companyConfigApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    companyConfig: builder.query({
      query: (idEmpresa) => ({
        url: `/empresa/${idEmpresa}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyCompanyConfigQuery } = companyConfigApiSlice;
