import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './features/api/apiSlice';
import authReducer from './features/auth/authSlice';
import companyConfigReducer from './features/companyConfigSlice/companyConfigSlice';
import cartReducer from './features/cart/cartSlice';

import localForage from 'localforage';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key: 'rootBenefits4bRedux',
  storage: localForage,
};

const reducers = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  companyConfig: companyConfigReducer,
  cart: cartReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
