import { createSlice } from '@reduxjs/toolkit';

type cartItem = {
  idcarrito: number;
  fecha_disfrute: string;
  adjuntos: string;
  dias_disfrute: number;
  notas: string;
  titulo_beneficio: string;
  puntaje: number;
  icono: string;
  idbeneficio_categoria: number;
  beneficio_categoria: string;
  fecha_adicion: string;
};

const initialState: cartItem[] = [];

const cartSlice = createSlice({
  name: 'cart',
  initialState: initialState,
  reducers: {
    addToCart: (state, action) => {
      const { idcarrito, fecha_disfrute, adjuntos, dias_disfrute, notas, titulo_beneficio, puntaje, icono, idbeneficio_categoria, beneficio_categoria, fecha_adicion } = action.payload;
      const updatedCart = [...state, { idcarrito, fecha_disfrute, adjuntos, dias_disfrute, notas, titulo_beneficio, puntaje, icono, idbeneficio_categoria, beneficio_categoria, fecha_adicion }];
      return updatedCart;
    },
    delFromCart: (state, action) => {
      const { idcarrito } = action.payload;
      return state.filter((item: any) => Number(item.idcarrito) !== Number(idcarrito));
    },
    clearCart: (state) => {
      return [];
    },
    setCart: (state, action) => {
      /* console.log({ action }); */
      return action.payload;
    },
  },
});

export const { addToCart, delFromCart, clearCart, setCart } = cartSlice.actions;
export const selectCart = (state: any) => state.cart;

export default cartSlice.reducer;
