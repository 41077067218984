import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: { usuario: null, token: null },
  reducers: {
    setCredentials: (state, action) => {
      const { usuario, token } = action.payload;
      state.usuario = usuario;
      state.token = token;
    },
    logOut: (state, action) => {
      state.usuario = null;
      state.token = null;
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;
export const selectCurrentUser = (state: any) => state.auth.usuario;
export const selectCurrentToken = (state: any) => state.auth.token;

export default authSlice.reducer;
