import { IonModal } from '@ionic/react';
import { selectCurrentSkin } from '../../../redux/features/companyConfigSlice/companyConfigSlice';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

const StyledModal: any = styled(IonModal)`
  /* estilos heredados de la configuración */
  ${(props: any) =>
    props.styles &&
    css`
      --ion-color-primary: ${props.styles.primary};
      --ion-color-secondary: ${props.styles.secondary};
      --ion-color-tertiary: ${props.styles.tertiary};
      --ion-color-success: ${props.styles.success};
      --ion-color-warning: ${props.styles.warning};
      --ion-color-danger: ${props.styles.danger};
      --border-radius: 4px;
    `}

  /* estilos del modal de contacto/login */
  ${(props: any) =>
    props.smallModal &&
    css`
      --height: 300px;
    `}

      /* estilos del modal de contacto */
  ${(props: any) =>
    props.manageRequestModal &&
    css`
      .item-form {
        margin-top: 12px;
      }
    `}

  /* contenedor de noticias del home */
  .home-news-modal-container {
    h5 {
      margin-top: 0;
      font-weight: bold;
      text-align: center;
    }

    img {
      object-fit: contain;
      height: 300px;
      float: left;
      margin-right: 12px;
    }
  }
`;

const Modal = (props: any) => {
  const activeCustomization = useSelector(selectCurrentSkin);
  return (
    <StyledModal {...props} styles={activeCustomization}>
      {props.children}
    </StyledModal>
  );
};

export default Modal;
