import { IonApp, setupIonicReact } from '@ionic/react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
/* import Home from './pages/Home';
import { Login } from './pages/Login/Login'; */

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import 'react-lazy-load-image-component/src/effects/blur.css';
import './theme/variables.css';

import ConfigProvider from './components/ConfigProvider';
import Layout from './components/UI/Layout/Layout';
import PrivateRoute from './router/PrivateRoute';
import { PUBLIC_ROUTES } from './router/routes';
import Loader from './components/UI/Loader/Loader';
import { Suspense } from 'react';
import ErrorBoundary from './components/ErrorComponent/ErrorBoundary';
import ErrorFallback from './components/ErrorComponent/ErrorFallback';

setupIonicReact({
  rippleEffect: false,
  mode: 'md',
});

const App: React.FC = () => (
  <ConfigProvider>
    <ErrorBoundary fallback={<ErrorFallback />}>
      <IonApp>
        <BrowserRouter basename={process.env.REACT_WEBSITE_BASE_PATH}>
          <Suspense fallback={<Loader />}>
            <Switch>
              {PUBLIC_ROUTES.map((route, idx) => (
                <Route key={idx} path={route.path} exact={route.exact} component={route.component} />
              ))}

              <PrivateRoute path="/main" component={Layout} />
              <Redirect to="/login" />
            </Switch>
          </Suspense>
        </BrowserRouter>
      </IonApp>
    </ErrorBoundary>
  </ConfigProvider>
);

export default App;
