import { apiSlice } from './apiSlice';

export const cart = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    requestCartItems: builder.query<RequestCartItemsResponse, null>({
      query: () => ({
        url: `/cart/`,
        method: 'GET',
      }),
    }),

    addCartItem: builder.mutation({
      query: ({ formData }) => ({
        url: `/cart/add`,
        body: formData,
        method: 'POST',
      }),
    }),

    delCartItem: builder.mutation({
      query: ({ idCart }) => ({
        url: `/cart/del`,
        body: {
          idCart,
        },
        method: 'POST',
      }),
    }),

    checkout: builder.mutation({
      query: () => ({
        url: `/cart/checkout`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useLazyRequestCartItemsQuery, useAddCartItemMutation, useDelCartItemMutation, useCheckoutMutation } = cart;

interface RequestCartItemsResponse {
  elementos: Elemento[];
  status: 'ok' | 'error';
}

interface Elemento {
  idcarrito: number;
  fecha_disfrute: string;
  adjuntos: string;
  dias_disfrute: number;
  notas: string;
  titulo_beneficio: string;
  puntaje: number;
  icono: string;
  idbeneficio_categoria: number;
  beneficio_categoria: string;
  fecha_adicion: string;
  aliado: number;
  marca: string;
}