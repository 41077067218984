import { IonAccordion, IonAccordionGroup, IonAvatar, IonButton, IonContent, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { accessibilityOutline, earthOutline, giftOutline, homeOutline, logOutOutline, megaphoneOutline, peopleOutline, removeOutline, ribbonOutline } from 'ionicons/icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import portraitPlaceholder from '../../../assets/img/portrait_placeholder.jpg';
import { logOut, selectCurrentUser } from '../../../redux/features/auth/authSlice';
import { selectCurrentBenefitsCategories, selectCurrentCompanyConfig } from '../../../redux/features/companyConfigSlice/companyConfigSlice';
import { APP_VERSION, AWS_BUCKET } from '../../../utils/constants';
import ContactModal from '../../ContactModal/ContactModal';
import { MenuSection, UserSection } from './MobileMenu.styles';
import { useEffect, useState } from 'react';

interface AppPage {
  url: string;
  icon?: string;
  title: string;
  haveSubmenu?: boolean;
  isBenefits?: boolean;
  subMenu?: AppPage[];
  isComponent?: boolean;
  key: number;
  active?: boolean;
  bdKey?: string;
}

const Menu = () => {
  const appPages: AppPage[] = [
    {
      title: 'Home',
      url: '/main',
      icon: homeOutline,
      key: 1,
    },
    {
      title: 'Beneficios',
      url: '#',
      icon: ribbonOutline,
      isBenefits: true,
      key: 2,
    },
    {
      title: 'Contacto',
      url: '/main/contacto',
      icon: megaphoneOutline,
      isComponent: true,
      key: 3,
    },
  ];

  const user = useSelector(selectCurrentUser);
  const beneficiosActivos = useSelector(selectCurrentBenefitsCategories);
  const config = useSelector(selectCurrentCompanyConfig);
  const dispatch = useDispatch();
  const [administrablePages, setAdministrablePages] = useState<AppPage[]>([
    {
      title: 'Actividades',
      url: '/main/actividades',
      icon: accessibilityOutline,
      active: false,
      bdKey: 'ACTactivo',
      key: 4,
    },
    {
      title: 'Voluntariados',
      url: '/main/voluntariados',
      icon: earthOutline,
      active: false,
      bdKey: 'VOLactivo',
      key: 5,
    },
    {
      title: 'Convenios',
      url: '/main/convenios',
      icon: giftOutline,
      active: false,
      bdKey: 'CNVactivo',
      key: 6,
    },
    {
      title: 'Mi equipo',
      url: '/main/mi-equipo/helpers',
      icon: peopleOutline,
      active: true,
      key: 7,
    },
  ]);

  const [profilePhotoSuccess, setProfilePhotoSuccess] = useState(true);

  useEffect(() => {
    if (config) {
      const updatedPages = administrablePages.map((page) => {
        if (page.bdKey) {
          return {
            ...page,
            //@ts-ignore
            active: !!config[page.bdKey],
          };
        } else {
          return {
            ...page,
            //@ts-ignore
            active: !!user?.equipo,
          };
        }
      });
      setAdministrablePages(updatedPages);
    }
  }, [config]);

  return (
    <IonMenu contentId="main" type="overlay" side="end">
      <IonContent fullscreen>
        <UserSection className="user-info">
          <IonMenuToggle autoHide={false}>
            <Link to="/main/perfil/info">
              <IonAvatar slot="center">
                {profilePhotoSuccess ? (
                  <LazyLoadImage src={`${AWS_BUCKET}${user.foto}`} alt={user.nombre} onError={() => setProfilePhotoSuccess(false)} />
                ) : (
                  <LazyLoadImage src={portraitPlaceholder} alt={user.nombre} />
                )}
              </IonAvatar>
            </Link>
          </IonMenuToggle>

          <p className="userName">{user.nombre}</p>
          <p className="userMail">{user.email}</p>
          <p className="appVersion">version-{APP_VERSION}</p>
        </UserSection>
        <MenuSection className="menu-items">
          <IonList id="inbox-list">
            {appPages?.map((appPage) =>
              appPage.isBenefits ? (
                <IonAccordionGroup key={appPage.key}>
                  <IonAccordion value="first">
                    <IonItem slot="header">
                      <IonIcon slot="start" icon={appPage.icon} md={appPage.icon} />
                      <IonLabel>{appPage.title}</IonLabel>
                    </IonItem>
                    {beneficiosActivos?.map((beneficio: any) => (
                      <div slot="content" key={beneficio.idbeneficio_categoria}>
                        <IonMenuToggle autoHide={false}>
                          <Link aria-label={`Ir a los beneficios ${beneficio.categoria}`} to={`/main/beneficios?idcategoria=${beneficio.idbeneficio_categoria}`}>
                            <IonItem lines="none" detail={false}>
                              <IonIcon slot="start" icon={removeOutline} md={removeOutline} />
                              <IonLabel>{beneficio.categoria}</IonLabel>
                            </IonItem>
                          </Link>
                        </IonMenuToggle>
                      </div>
                    ))}
                  </IonAccordion>
                </IonAccordionGroup>
              ) : (
                <IonMenuToggle key={appPage.key} autoHide={false}>
                  {appPage.isComponent ? (
                    <ContactModal isMobile icon={appPage.icon} title={appPage.title} />
                  ) : (
                    <Link to={appPage.url}>
                      <IonItem lines="none" detail={false}>
                        <IonIcon slot="start" icon={appPage.icon} md={appPage.icon} />
                        <IonLabel>{appPage.title}</IonLabel>
                      </IonItem>
                    </Link>
                  )}
                </IonMenuToggle>
              )
            )}
            {administrablePages
              ?.filter((page) => page.active)
              .map((appPage) => (
                <IonMenuToggle key={appPage.key} autoHide={false}>
                  {appPage.isComponent ? (
                    <ContactModal isMobile icon={appPage.icon} title={appPage.title} />
                  ) : (
                    <Link to={appPage.url}>
                      <IonItem lines="none" detail={false}>
                        <IonIcon slot="start" icon={appPage.icon} md={appPage.icon} />
                        <IonLabel>{appPage.title}</IonLabel>
                      </IonItem>
                    </Link>
                  )}
                </IonMenuToggle>
              ))}
            <IonButton onClick={() => dispatch(logOut(null))} color="danger" expand="full">
              <IonIcon icon={logOutOutline} />
              Salir
            </IonButton>
          </IonList>
        </MenuSection>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
