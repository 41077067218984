import { useSelector } from 'react-redux';
import { selectCurrentToken } from './authSlice';
import jwt_decode from 'jwt-decode';

const useIsAuthenticated = () => {
  const token = useSelector(selectCurrentToken);
  if (token) {
    const decodedJWT: any = jwt_decode(token);
    const { nodespuesde, emisor } = decodedJWT;
    const now = new Date().getTime();
    if (now > nodespuesde * 1000) {
      console.log('now > nodespuesde * 1000 - Token expiro');
      return false;
    }

    if (emisor !== 'Benefits-4B.JWT.issuer.com') {
      return false;
    }

    return true;
  } else {
    return false;
  }
};

export { useIsAuthenticated };
