import styled from 'styled-components';
import { IonItem } from '@ionic/react';

const IonItemForm = styled(IonItem)`
  border: 1px solid lightgray;
  border-radius: 4px;
  margin-bottom: 8px;

  .dropzone {
    input {
      display: block !important;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  ion-item::part(native) {
    background: #19422d;
    color: #fff;

    border-color: #fff;
    border-style: dashed;
    border-width: 2px;

    border-radius: 20px;
  }

  ion-item::part(detail-icon) {
    color: white;
    opacity: 1;
    font-size: 20px;
  }
`;

IonItemForm.defaultProps = {
  lines: 'none',
  fill: 'solid',
};

export { IonItemForm };
