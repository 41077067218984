import styled from 'styled-components';

const TopBarContainer: any = styled.div`
  background-color: #333334;
  font-size: 0.8rem;
  display: ${(props: any) => (props.isMobile ? 'none' : 'block')};
  .submenu-profile-container {
    display: flex;
    justify-content: flex-end;
    padding: 4px;
    max-width: 1615px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
  }
`;

export { TopBarContainer };
