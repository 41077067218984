import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setCompanyConfig, selectCurrentSkin } from '../redux/features/companyConfigSlice/companyConfigSlice';
import { useLazyCompanyConfigQuery } from '../redux/features/companyConfigSlice/companyConfigApiSlice';
import Loader from './UI/Loader/Loader';

type Props = {
  children: JSX.Element | JSX.Element[];
};

const ThemeStyles: any = styled.section`
  ${(props: any) =>
    props.styles &&
    css`
      --ion-color-primary: ${props.styles.primary};
      --ion-color-secondary: ${props.styles.secondary};
      --ion-color-tertiary: ${props.styles.tertiary};
      --ion-color-success: ${props.styles.success};
      --ion-color-warning: ${props.styles.warning};
      --ion-color-danger: ${props.styles.danger};
    `}
`;

const ConfigProvider = ({ children }: Props) => {
  const [companyConfig, { isLoading }] = useLazyCompanyConfigQuery();
  const activeCustomization = useSelector(selectCurrentSkin);

  const dispatch = useDispatch();

  const getCurrentConfig = async () => {
    try {
      const config: any = await companyConfig(process.env.REACT_APP_COMPANY).unwrap();
      /*       console.log({ config }); */
      if (!Array.isArray(config.empresa)) {
        dispatch(setCompanyConfig(config));
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getCurrentConfig();
    /*     console.log({ activeCustomization }); */
  }, [activeCustomization]);

  return isLoading ? <Loader /> : <ThemeStyles styles={activeCustomization}>{children}</ThemeStyles>;
};

export default ConfigProvider;
