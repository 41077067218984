import { IonPage, IonHeader } from '@ionic/react';
import { useSelector } from 'react-redux';

import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
// import useMediaQuery from '../../../hooks/useMediaQuery';
import { selectCurrentUser } from '../../../redux/features/auth/authSlice';

import { PRIVATE_ROUTES } from '../../../router/routes';
import DesktopMenu from '../DesktopMenu/DesktopMenu';
// import Footer from '../Footer/Footer';
import MobileMenu from '../MobileMenu/MobileMenu';
import TopBar from '../TopBar/TopBar';

const Layout = () => {
  const { url } = useRouteMatch();
  // const isDesktop = useMediaQuery('(min-width: 500px)');
  const user = useSelector(selectCurrentUser);

  return (
    <>
      <MobileMenu />
      <IonPage id="main">
        <IonHeader color="#fff">
          <TopBar />
          <DesktopMenu />
        </IonHeader>

        <Switch>
          {PRIVATE_ROUTES?.filter((route) => {
            if (route.path === 'mi-equipo' && !!user.equipo) {
              return true;
            }
            if (route.path !== 'mi-equipo') {
              return true;
            }
            return false;
          }).map((route, idx) => {
            return (
              <Route exact={route.exact} key={idx} path={`${url}/${route.path}`}>
                <route.component />
              </Route>
            );
          })}
          <Redirect to="/main" />
        </Switch>
      </IonPage>
    </>
  );
};

export default Layout;
