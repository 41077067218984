import { IonButton, IonIcon } from '@ionic/react';
import { useDispatch } from 'react-redux';
import { logOutOutline } from 'ionicons/icons';
import { logOut } from '../../../redux/features/auth/authSlice';
import useMediaQuery from '../../../hooks/useMediaQuery';
import PointsCount from '../PointsCount/PointsCount';
import { TopBarContainer } from './TopBar.styles';
import { useEffect, useState } from 'react';
import { useLazyGetProfilePointsQuery } from '../../../redux/features/api/perfil';

const TopBar = () => {
  const [puntosUser, setPuntosUser] = useState({ consumidos: 0, puntos: 0, saldo: 0 });
  const isMobile = useMediaQuery('(max-width: 1134px)');
  const dispatch = useDispatch();

  const [userPoints] = useLazyGetProfilePointsQuery();

  const getUserPoints = async () => {
    try {
      const response = await userPoints({}).unwrap();
      if (Object.keys(response).length > 0) {
        const { puntos, consumidos, saldo } = response;
        /*     console.log({ puntos, consumidos, saldo }); */
        setPuntosUser({ puntos, consumidos, saldo });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getUserPoints();
  }, []);

  return (
    <TopBarContainer isMobile={isMobile}>
      <div className="submenu-profile-container">
        <PointsCount name="Cargados" points={puntosUser.puntos ? puntosUser.puntos : 0} />
        <PointsCount name="Consumidos" points={puntosUser.consumidos ? puntosUser.consumidos : 0} />
        <PointsCount name="Disponibles" points={puntosUser.saldo ? puntosUser.saldo : 0} />

        <IonButton onClick={() => dispatch(logOut(null))} color="danger">
          <IonIcon icon={logOutOutline} />
        </IonButton>
      </div>
    </TopBarContainer>
  );
};

export default TopBar;
