import styled from 'styled-components';

const PointCountContainer = styled.div`
  text-align: center;
  background: #333334;
  border-radius: 8px;
  border: 2px solid #14e3e5;
  margin-right: 8px;
  p {
    color: white;
    font-style: italic;
    margin: 8px;

    span:first-child {
      font-weight: bold;
      font-style: normal;
    }

    span:last-child {
      font-style: normal;
      font-weight: bold;
      color: #14e3e5;
    }
  }
`;

export { PointCountContainer };
