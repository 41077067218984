import styled from 'styled-components';

const UserSection = styled.section`
  border-bottom: 1px solid;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  padding: 12px 12px 0;

  ion-avatar {
    border: 2px solid;
  }

  .userName {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0;
  }
  .userMail {
    font-size: 0.9rem;
    margin: auto;
  }
  .appVersion {
    margin: 8px auto 12px;
    font-size: 0.8rem;
    color: #7a7a7a;
  }
`;

const MenuSection = styled.section`
  a {
    text-decoration: none;
  }
`;

export { UserSection, MenuSection };
